const TRANSLATABLE_SERVICE_TEMPLATE_ATTRIBUTES_LIST = [
    "name",
    "headline",
    "subHeadline",
    "provider",
    "providerNotice",
    "providerPrivacyPolicyUrl",
    "providerLegalNoticeUrl",
    "groupNotice",
    "legalBasisNotice",
    "technicalHandlingNotice",
    "createContentBlockerNotice"
];
Object.freeze(TRANSLATABLE_SERVICE_TEMPLATE_ATTRIBUTES_LIST);
const EXTENDABLE_SERVICE_TEMPLATE_ATTRIBUTES_LIST = [
    "name",
    "codeOnPageLoad",
    "googleConsentModeConsentTypes",
    "codeOptIn",
    "codeOptOut",
    "createContentBlockerNotice",
    "dataProcessingInCountries",
    "dataProcessingInCountriesSpecialTreatments",
    "deleteTechnicalDefinitionsAfterOptOut",
    "dynamicFields",
    "executeCodeOptInWhenNoTagManagerConsentIsGiven",
    "executeCodeOptOutWhenNoTagManagerConsentIsGiven",
    "group",
    "groupNotice",
    "isEmbeddingOnlyExternalResources",
    "isProviderCurrentWebsite",
    "legalBasis",
    "legalBasisNotice",
    "provider",
    "providerNotice",
    "providerPrivacyPolicyUrl",
    "providerLegalNoticeUrl",
    "purposes",
    "shouldUncheckContentBlockerCheckbox",
    "shouldUncheckContentBlockerCheckboxWhenOneOf",
    "tagManagerOptInEventName",
    "tagManagerOptOutEventName",
    "technicalDefinitions",
    "technicalHandlingNotice"
];
Object.freeze(EXTENDABLE_SERVICE_TEMPLATE_ATTRIBUTES_LIST);
var EServiceTemplateGroup;
(function(EServiceTemplateGroup) {
    EServiceTemplateGroup["Essential"] = "essential";
    EServiceTemplateGroup["Functional"] = "functional";
    EServiceTemplateGroup["Statistics"] = "statistics";
    EServiceTemplateGroup["Marketing"] = "marketing";
})(EServiceTemplateGroup || (EServiceTemplateGroup = {}));
var EServiceTemplateLegalBasis;
(function(EServiceTemplateLegalBasis) {
    EServiceTemplateLegalBasis["Consent"] = "consent";
    EServiceTemplateLegalBasis["LegitimateInterest"] = "legitimate-interest";
    EServiceTemplateLegalBasis["LegalRequirement"] = "legal-requirement";
})(EServiceTemplateLegalBasis || (EServiceTemplateLegalBasis = {}));
var EServiceTemplateDataProcessingInCountriesSpecialTreatment;
(function(EServiceTemplateDataProcessingInCountriesSpecialTreatment) {
    EServiceTemplateDataProcessingInCountriesSpecialTreatment["ProviderIsSelfCertifiedTransAtlanticDataPrivacyFramework"] = "provider-is-self-certified-trans-atlantic-data-privacy-framework";
    EServiceTemplateDataProcessingInCountriesSpecialTreatment["StandardContractualClauses"] = "standard-contractual-clauses";
})(EServiceTemplateDataProcessingInCountriesSpecialTreatment || (EServiceTemplateDataProcessingInCountriesSpecialTreatment = {}));
var EServiceTemplateGoogleConsentModeTypes;
(function(EServiceTemplateGoogleConsentModeTypes) {
    EServiceTemplateGoogleConsentModeTypes["AdStorage"] = "ad_storage";
    EServiceTemplateGoogleConsentModeTypes["AdUserData"] = "ad_user_data";
    EServiceTemplateGoogleConsentModeTypes["AdPersonalization"] = "ad_personalization";
    EServiceTemplateGoogleConsentModeTypes["AnalyticsStorage"] = "analytics_storage";
    EServiceTemplateGoogleConsentModeTypes["FunctionalityStorage"] = "functionality_storage";
    EServiceTemplateGoogleConsentModeTypes["PersonalizationStorage"] = "personalization_storage";
    EServiceTemplateGoogleConsentModeTypes["SecurityStorage"] = "security_storage";
})(EServiceTemplateGoogleConsentModeTypes || (EServiceTemplateGoogleConsentModeTypes = {}));
const EXPORT_REMOVABLE_SERVICE = [
    "id",
    "logo",
    "logoId",
    "release",
    "releaseId",
    "extends",
    "next",
    "nextId",
    "pre",
    "preId",
    "extendsId",
    "translationIds",
    "extendedTemplateId",
    "translationInfo",
    "purposeIds",
    "dynamicFieldIds",
    "technicalDefinitionIds",
    "translatableRequiredFields",
    "translatedRequiredFields",
    "translatableOptionalFields",
    "translatedOptionalFields",
    "version"
];
Object.freeze(EXPORT_REMOVABLE_SERVICE);
export { EServiceTemplateDataProcessingInCountriesSpecialTreatment, EServiceTemplateGoogleConsentModeTypes, EServiceTemplateGroup, EServiceTemplateLegalBasis, EXPORT_REMOVABLE_SERVICE, EXTENDABLE_SERVICE_TEMPLATE_ATTRIBUTES_LIST, TRANSLATABLE_SERVICE_TEMPLATE_ATTRIBUTES_LIST };
